<template>
  <loading-state v-if="isLoadingComponent" />
  <div class="listGradeStudentReport" v-else>
    <!-- <h3>Student Assignment in KD-1</h3> -->
    <div class="d-flex align-items-center">
      <div class="w-50">
        <h3>Student NIS</h3>
        <p>{{ result.student.nis || "--" }}</p>
      </div>
      <div class="w-50">
        <h3>Student Name</h3>
        <p>{{ result.student.name || "--" }}</p>
      </div>
    </div>
    <div class="d-flex align-items-center mt-3" v-if="idKdMaster">
      <div class="w-50">
        <h3>Knowledge of KD-{{ result.kd.no || "-" }}</h3>
        <p>{{ result.kd.knowledge || "--" }}</p>
      </div>
      <div class="w-50">
        <h3>Skill of KD-{{ result.kd.no || "-" }}</h3>
        <p>{{ result.kd.skill || "--" }}</p>
      </div>
    </div>
    <hr />

    <a-table :columns="columns" :data-source="result.listData" class="table-scroll" :scroll="{ y: 350 }"
      :pagination="{ pageSize: 30, hideOnSinglePage: true }" bordered>
      <div slot="detail" slot-scope="value, record" class="d-flex flex-column">
        <p class="mb-2">{{ record.date }}</p>
        <assignment-type class="mb-4" :assignmentType="record.type" withText />
        <a-button class="btn btn-outline-primary rounded" @click="seeDetail(record.id)">See Detail</a-button>
      </div>
      <div slot="remedial" slot-scope="value">
        <a-checkbox :checked="value" disabled>
        </a-checkbox>
      </div>
      <div slot="grade" slot-scope="value" class="">
        <grade-color v-if="value" :grade="value" />
        <span v-else>--</span>
      </div>
      <div v-if="idKdMaster" slot="footer" class="d-flex align-items-center px-2">
        <p class="mr-auto">
          Average of {{ type === "assignment" ? "Assignment" : "Written" }} in
          KD-{{ result.kd.no || "-" }}:
        </p>
        <grade-color v-if="result.kd.average" :grade="result.kd.average" />
        <span v-else>--</span>
      </div>
    </a-table>
  </div>
</template>

<script>
const LoadingState = () => import('@/components/app/LoadingState')
const GradeColor = () => import('@/components/app/GradeColor')
const AssignmentType = () => import('@/components/app/shared/AssignmentType')
const columns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    scopedSlots: { customRender: 'no' },
    width: 80,
    align: 'center',
  },
  {
    title: 'Detail',
    dataIndex: 'detail',
    key: 'detail',
    scopedSlots: { customRender: 'detail' },
    width: 200,
  },
  // {
  //   title: 'Remedial',
  //   dataIndex: 'isRemedial',
  //   key: 'remedial',
  //   align: 'center',
  //   scopedSlots: { customRender: 'remedial' },
  //   width: 105,
  // },
  {
    title: 'Grade',
    dataIndex: 'grade',
    key: 'grade',
    scopedSlots: { customRender: 'grade' },
    align: 'center',
    width: 100,
  },
]
export default {
  components: {
    LoadingState,
    GradeColor,
    AssignmentType,
  },
  props: {
    idKdMaster: {
      type: Number,
    },
    idKelasTahunAjaran: {
      type: Number,
      required: true,
    },
    // type = assignment/cbt
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      columns,
      dataTable: [
        // {
        //   no: 1,
        //   date: 'Monday, 1 July 2021',
        //   assignment_type: 'Multiple Choice',
        //   grade: 92.2,
        // },
        // {
        //   no: 2,
        //   date: 'Tuesday, 3 July 2021',
        //   assignment_type: 'Essay',
        //   grade: 50,
        // },
        // {
        //   no: 3,
        //   date: 'Friday, 20 July 2021',
        //   assignment_type: 'File Upload',
        //   grade: 23,
        // },
        // {
        //   no: 4,
        //   date: 'Wednesday, 20 July 2021',
        //   assignment_type: 'File Upload',
        //   grade: null,
        // },
        // {
        //   no: 5,
        //   date: 'Friday, 21 July 2021',
        //   assignment_type: 'Multiple Choice',
        //   grade: null,
        // },
        // {
        //   no: 5,
        //   date: 'Friday, 21 July 2021',
        //   assignment_type: 'Multiple Choice',
        //   grade: null,
        // },
        // {
        //   no: 5,
        //   date: 'Friday, 21 July 2021',
        //   assignment_type: 'Multiple Choice',
        //   grade: null,
        // },
      ],
      result: {
        student: {
          name: null,
          nis: null,
        },
        kd: {
          no: null,
          knowledge: null,
          skill: null,
          average: null,
        },
        listData: [],
      },
      isLoadingComponent: false,
    }
  },
  methods: {
    seeDetail(id) {
      console.log(id)
      const name = this.type === 'assignment' ? 'Grading Assignments Teacher' : ['PH', 'PTS', 'PAS'].includes(this.type) ? 'Result Cbt Teacher' : null
      if (name) {
        const routeData = this.$router.resolve({ name, params: { id } })
        window.open(routeData.href, '_blank')
      }
    },
    async fetchData() {
      try {
        // if (this.type === 'term') {
        //   this.result = {
        //     ...this.result,
        //     student: {
        //       name: 'Alfina Damayanti Puspadini',
        //       nis: '91920188',
        //     },
        //     listData: [
        //       {
        //         date: 'Monday, 26 July 2021',
        //         grade: 75,
        //         id: 411,
        //         key: 411,
        //         no: 1,
        //         type: 'Multiple Choice & Essay',
        //         isRemedial: true,
        //       },
        //     ],
        //   }
        //   return
        // }
        this.isLoadingComponent = true
        const { idKdMaster, idKelasTahunAjaran, type } = this
        const idMapel = this.$route.params.idMapel
        const data = await this.$store.dispatch('report/FETCH_LIST_GRADE_BY_STUDENT', { idKdMaster, idKelasTahunAjaran, type, idMapel })

        this.isLoadingComponent = false
        this.result = data
        this.result.listData = this.result.listData.map((res, i) => {
          const isRemedial = i > 0
          return {
            ...res,
            isRemedial,
          }
        })
      } catch (error) {
        console.log(error)
        this.$notification.error({
          message: 'Error',
          description: 'The process of fetching data encountered problems. Please try again later',
        })
      } finally {
        this.isLoadingComponent = false
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss">
.listGradeStudentReport {
  .table-scroll {
    .ant-spin-nested-loading .ant-spin-container .ant-table {
      overflow-x: auto;
      // .ant-table-row-cell-ellipsis {
      //   @media (max-width: 769px) {
      //     width: 200px;
      //   }
      // }
    }
  }
}
</style>
